<template>
    <router-view class="full-height-layout fhf" :carrier-data="carrierData" :service-level-data="serviceLevelData" ref="routeview"></router-view>
</template>

<script>
import CONFIG from '@root/config'

export default {
    name: 'CarrierServiceLevelView',
    props: ['carrierData'],
    data () {
        return {
            serviceLevelData: null,
        }
    },
    computed: {
        breadcrumbElement () {
            return {
                name: (this.serviceLevelData && this.serviceLevelData.serviceLevelName ? this.serviceLevelData.serviceLevelName : '...'),
            }
        },
    },

    created () {
        if (!this.isAllowedTo('getServiceLevelById')) {
            return
        }
        const getServiceLevelUrl = CONFIG.API.ROUTES.CARRIERS.SERVICELEVELS.GET
            .replace('{carrierId}', this.$route.params.carrierId)
            .replace('{serviceLevelId}', this.$route.params.serviceLevelId)

        this.loading = true
        this.addJob(getServiceLevelUrl)
        this.$api.get(getServiceLevelUrl).then(({ data }) => {
            this.serviceLevelData = data.data
        }).catch(({ data }) => {
            this.$root.$emit('notification:global', {
                message: `Getting service level failed. Please try again.`,
                type: 'error',
                errors: data,
            })
        }).finally(() => {
            this.finishJob(getServiceLevelUrl)
            this.loading = false
        })
    },
}
</script>
